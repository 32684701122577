import React from 'react'
import { AccordionSet } from '@saatva-bits/pattern-library.components.accordion-set'
import { Accordion } from '@saatva-bits/pattern-library.components.accordion'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import accordionData from './ProductAccordionConfig'
import styles from './ProductAccordion.module.scss'

const ProductAccordion = ({
    contentKey,
    classNameModifier = 'bgContrastWhite',
    className
}) => {
    const accordionClassNames = classNames(styles.productAccordion__accordionItem, className)

    return (
        <AccordionSet accordionData={accordionData[contentKey]}>
            <Accordion
                titleClassName="t-heading4 u-marginBottom--none"
                contentContainerClassName={styles.productAccordion__contentContainer}
                className={accordionClassNames}
                singleAccordion={false}
                outsideClick={false}
                allowMultiOpen={true}
                classNameModifier={classNameModifier}
            />
        </AccordionSet>
    )
}

ProductAccordion.propTypes = {
    productCode: PropTypes.string,
    classNameModifier: PropTypes.string,
    className: PropTypes.string
}

export default ProductAccordion
