import React from 'react'

const MattressFaceOff = () => {
    return (
        <section className="consumerReport">
            <div className='consumerReport__container'>
                <div className="consumerReport__description">
                    <div className="consumerReport__description__main">
                        <div className='t-bodyLg consumerReport__description__subHeader'>
                            See what Consumer Reports has to say about the
                        </div>
                        <div className='t-bodyLg consumerReport__description__subHeader consumerReport__description__subHeader__bold'>
                            Saatva Solaire Mattress
                        </div>
                        <a
                            href="https://reprints.theygsgroup.com/cr/reprints/Saatva_MattressFaceOff_Article.pdf"
                            target="_blank"
                            rel="noreferrer noopener"
                            className='t-link consumerReport__description__link'>
                            Read Solaire Review
                        </a>
                    </div>
                    <div className="consumerReport__description__bottom">
                        <span className='t-disclaimer consumerReport__description__disclaimer'>Consumer Reports does not endorse products and services.</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MattressFaceOff
